
<template>
  <a-spin :spinning="spinning" tip="Loading...">
    <div class="carelist">
      <div class="header">
        <el-button size="mini" plain type="primary" @click="()=>this.$router.back()">{{$t("care.back")}}</el-button>
        <el-button size="mini"  plain type="primary" icon="el-icon-refresh"  @click="getList()"></el-button>
        <el-button size="mini" plain type="primary" icon="el-icon-link"  @click="handleCustomLink()">{{$t("care.copyCustom")}}</el-button>
        <el-button size="mini" plain type="primary" icon="el-icon-plus"  @click="handleAdd()">{{$t("care.addCare")}}</el-button>
      </div>
      <div class="content">
        <a-modal v-model="visible">
          <template #footer>
            <a-button key="back" @click="visible=false">{{$t("care.cancel")}}</a-button>
            <a-button key="submit" type="danger" :loading="deleteLoading"  @click="handleOk">{{$t("care.delete")}}</a-button>
          </template>
          <span>{{$t("care.deleteConfirm")}}？</span>
        </a-modal>
        <el-empty :description="$t('care.notSetCare')" v-if="tableData.length===0"></el-empty>
        <el-table
            v-else
            class="table"
            :data="tableData"
            :height="tableHeight"
            highlight-current-row
        >
          <el-table-column
              prop="group_name"
              :label="$t('care.group')"
          >
          </el-table-column>
          <el-table-column
              prop="name"
              :label="$t('care.care')"
          >
            <template slot-scope="scope">
              <div class="avatar">
                <a-avatar icon="user" :src="scope.row.avatar" />
                <a-tag style="margin-left: 8px;" color="blue">{{scope.row.name}}</a-tag>
              </div>
            </template>
          </el-table-column>

          <el-table-column
              prop="created_at"
              :label="$t('care.created_at')"
          >
          </el-table-column>
          <el-table-column
              :label="$t('care.ops')"
          >
            <template slot-scope="scope">
              <div class="options">
                <div>
                  <el-button
                      size="mini"
                      type="danger" plain
                      icon="el-icon-delete"
                      @click="handleDelete(scope.$index, scope.row)"></el-button>
                </div>
                <div>
                  <el-button
                      size="mini"
                      type="primary" plain
                      icon="el-icon-phone-outline"
                      @click="handleLink(scope.$index, scope.row)">{{$t("care.workbench")}}</el-button>
                </div>
                <div>
                  <el-button
                      size="mini"
                      type="primary" plain
                      icon="el-icon-document-copy"
                      @click="handleCopyLink(scope.$index, scope.row)">{{$t("care.copyLink")}}</el-button>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <input v-model="copy_txt" type="text"  id="copy_dom" v-show="copying">
    </div>
  </a-spin>
</template>

<script>
import "./index.less";
import { message } from 'ant-design-vue';
import {deleteGroup, getGroups} from "@/libs/apis/group";
import {deleteGroupCare, getGroupCares} from "@/libs/apis/groupcare";
import liveLocalStore from "@/libs/utils/LocalStore";
import careMixin from "@/mixins/careMixin";
import clientMixin from "@/mixins/clientMixin";
import linkMixin from "@/mixins/linkMixin";

export default{
	data(){
		return {
      deleteLoading:false,
      visible:false,
      spinning:false,
      tableHeight:document.documentElement.clientHeight - 100,
      tableData:[],
      deleteRow:{},
		};
	},
	mounted: function(){
    this.$nextTick(function () {
      // 监听窗口大小变化
      let self = this;
      window.onresize = function() {
        self.tableHeight = document.documentElement.clientHeight - 100;
      }
    });
    this.getList();
	},
	components: {},
  computed: {
  },
	methods: {
    async handleOk(){
      try {
        this.deleteLoading=true;
        await deleteGroupCare(this.group_id,this.deleteRow.id)
        this.deleteLoading=false;
        this.visible=false;
        message.success(this.$t('care.opsSuccess'));
        this.getList();
      }catch (e) {
        message.error(e);
      }
    },
    async getList(){
      this.spinning=true;
      const rs=await getGroupCares(this.group_id,{
        append:['name','avatar','group_name'],
      });
      this.tableData= rs.data;
      this.spinning=false;
    },
    handleAdd(){
      this.$router.push({ name: 'addcare', params: { owner_id: this.owner_id,group_id: this.group_id }});
    },
    handleCustomLink(){
      const url= this.createCustomLink(this.group_id);
      this.postMsg('custom_link',JSON.stringify({
        url,
      }))
      this.copy(url);
    },
    handleLink(index,row){
      const url= this.createLink(this.group_id,row.uid);
      window.open(url);
		},
    handleCopyLink(index,row){
      const url= this.createLink(this.group_id,row.uid);
      this.postMsg('care_link',JSON.stringify({
        url,
      }))
      this.copy(url);
    },
    handleDelete(index,row){
      this.visible=true;
      this.deleteRow=row;
    },
	},
  mixins:[careMixin,clientMixin,linkMixin]
};
</script>
