<template>
  <div>
    <a-layout
      style="position: absolute; width: 100%; overflow: hidden; height: 100%"
    >
      <a-layout-header class="layout-header">
        <div class="header">
        <span class="setting">
          <img
            class="head_portrait"
            :src="care.picture"
            alt=""
          />
          <span class="username">{{care.username}}</span>
<!--          <a-dropdown>-->
<!--            <span class="ant-dropdown-link" href="#">-->
<!--              <a-icon type="setting" />-->
<!--            </span>-->
<!--          </a-dropdown>-->
        </span>
        </div>
      </a-layout-header>

      <a-layout>
        <a-layout-sider
          style="background: #fff"
          :width="broken ? '100%' : 350"
          breakpoint="lg"
          collapsedWidth="0"
          :trigger="null"
          v-model="collapsed"
          @collapse="onCollapse"
          @breakpoint="onBreakpoint"
        >
          <MessageBox
            v-if="initCliented"
            :select="select"
            :selectUser="selectUser"
            :uid="care.uid"
            :rtmToken="rtmToken"
            :group_id="group_id"
            ref="messageBox"
          />
        </a-layout-sider>

        <a-layout-content style="overflow: visible">
          <Message
            v-if="selectUser"
            :source="care"
            :destination2="selectUser"
            :broken="broken"
            :hideUserList="hideUserList"
            :showUserList="showUserList"
            :rtmToken="rtmToken"
            @onMessageFromOther="onMessageFromOther"
            :group_id="group_id"
            :connect_id="connect_id"
            :role="'care'"
            ref="messageList"
          />
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>

</template>

<script>
import MessageBox from "../../components/chat/index.vue";
import Message from "../../components/chat/message.vue";
import "./index.less";
import { message } from 'ant-design-vue'
import jwt_decode from "jwt-decode";
import {getToken} from "@/libs/apis/token";
import {getUserInfo} from "@/libs/apis/author";
import {setAuthor} from "@/libs/utils/author";
import AgoraRTM from "agora-rtm-sdk";
import LocalStore from "@/libs/utils/LocalStore";
import clientMixin from "@/mixins/clientMixin";
import _ from "lodash";
import {throttle} from "@/libs/utils/throttle";
import {careBelong, getCare, saveCare} from "@/libs/apis/care";
import {getUser} from "@/libs/apis/user";
import {addConnect} from "@/libs/apis/connect";
import {getGroup} from "@/libs/apis/group";
import {setCareLang} from "@/libs/utils/langStore";

export default {
  data() {
    return {
      group_id:null,
      connect_id:null,
      userName:'userName',
      head_portraitImg: require("../../assets/headPortrait.png"),
      collapsed: false,// 默认不收起
      broken: false,
      care:{
        username:'',
        picture:require("../../assets/headPortrait.png"),
        uid:null,
        is_care:true,
      },//客服用户信息
      rtmToken:{
        token:null,
        appid:null,
        uid:null,
      },
      selectUser:null,// 选择的对话人
      initCliented:false,
    };
  },
  computed: {
  },
  beforeMount() {
    // const query=this.$route.query;
    // console.log('参数',query);
    this.init();
  },
  methods: {
    async init(){
      const args= this.getUrlArgObject();
      const sign=this.makeSignature(args,'dfoptkf9rj4jh58dfy67ft98t60fguj');
      console.log("参数",args,sign)
      const locale=args?.locale??this.$i18n.locale;
      this.$i18n.locale=locale;
      setCareLang(locale);
      const care_uid=this.getQueryString('care_uid')
      const group_id=this.getQueryString('group_id')
      var timestamp_now=Math.round(new Date() / 1000);// 精确到秒
      if(args.sign!==sign){
        message.error(this.$t("care.errSign")).then(()=>{
          this.postMsg('init_err',this.$t("care.errSign"))
        });
        return;
      }else if(!args.timestamp){
        this.message2postmsg('init_err',this.$t("care.needParam")+'timestamp'); return;
      }else if(timestamp_now-args.timestamp>=60*60){ //60*60秒
        // message.error('签名过期').then(()=>{this.postMsg('sign_err','签名过期')});return;
      }

      if(!group_id){
        this.message2postmsg('init_err',this.$t("care.needParam")+'group_id'); return;
      }
      try {
        await getGroup(group_id);
      }catch (e) {
        this.message2postmsg('init_err',this.$t("care.unGroup"));return;
      }
      this.group_id=group_id;

      const Authorization=this.getQueryString('Authorization')
      if(!Authorization){
        this.message2postmsg('init_err',this.$t("care.Authorization")); return;
      }
      try {
        // const decoded=jwt_decode(Authorization)
        setAuthor(Authorization);
        // console.log('decoded',decoded)
        // // const user=await getUserInfo();
        // this.care.username=decoded.username
        // // this.care.picture=user.picture
        // this.care.uid=decoded.sub;
        if(!care_uid){
          this.message2postmsg('init_err',this.$t("care.needParam")+'uid');return;
        }
        this.care.uid=care_uid;
        const {belong}=await careBelong(this.group_id,this.care.uid);
        if(!belong){
          this.message2postmsg('init_err',this.$t("care.unCare"));return;
        }
        const user= await getUser(this.care.uid);
        this.care.picture=user.avatar;
        this.care.username=user.name;

        this.getToken();
      }catch (e) {
        this.message2postmsg('init_err',this.$t("care.tokenTimeout")); return;
      }
    },
    // async getCare(){
    //   let user=await getCare();
    //   if(!user){
    //     user=await saveCare(this.care.uid, this.care.username, this.care.picture);
    //   }
    //   return user;
    // },
    getImgUrl(imgurl){
      return this.checkImgExists(imgurl)?imgurl:this.head_portraitImg;
    },
    checkImgExists(imgurl) {
      let ImgObj = new Image(); //判断图片是否存在
      ImgObj.src = imgurl;
      //没有图片，则返回-1
      if (ImgObj.fileSize > 0 || (ImgObj.width > 0 && ImgObj.height > 0)) {
        return true;
      } else {
        return false;
      }
    },
    async getToken (){
      const token=await getToken(this.care.uid);
      this.rtmToken={
        token:token.rtmToken,
        appid:token.appId,
        uid:this.care.uid,
      };

      await this.initClient();
    },
    async initClient(){
      const client = AgoraRTM.createInstance(this.rtmToken.appid);
      await client.login(this.rtmToken);
      this.setClient(client);
      this.initCliented=true;
      client.on('ConnectionStateChanged', (newState, reason) => {
        if(newState=='DISCONNECTED'||newState=='ABORTED'){
          this.message2postmsg('offline',this.$t("care.disconnected"))
        }
      })
    },
    toLogout() {},
    onCollapse(collapsed, type) {
      if (type != "responsive") {
        this.$data.collapsed = true;
      } else {
        this.$data.collapsed = false;
      }
    },
    onBreakpoint(broken) {
      this.$data.broken = broken;
    },
    hideUserList() {
      this.$data.collapsed = true;
    },
    showUserList() {
      this.$data.collapsed = false;
    },
    async select(user) {
      try {
        const connect=await addConnect(this.group_id,this.care.uid,user.uid);
        this.connect_id= connect.id
        this.selectUser=user;
      }catch (e) {
        message.error(e.data.message)
      }
      if (this.broken) {
        this.$data.collapsed = true;
      }
    },
    onMessageFromOther(msg,send_uid){
      this.$refs.messageBox.onMessageFromOther(msg,send_uid);
    },
  },
  components: {
    MessageBox,
    Message,
  },
  mixins:[clientMixin],
};
</script>
