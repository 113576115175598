import 'babel-polyfill';
import Vue from 'vue'
import App from './App.vue'
import router from './router'

import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
Vue.use(Antd)
Vue.config.productionTip = false
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

//runtime


import {setupI18n} from './i18n'
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

async function bootstrap() {
  // const i18n = new VueI18n({
  //   locale: 'zh_CN',		//默认显示的语言
  //   messages:{
  //       'zh_CN':{
  //           test:"111zh"
  //       }
  //   }
  // })
  const i18n=await setupI18n();
  new Vue({
    i18n,
    router,
    render: h => h(App)
  }).$mount("#app")
}
bootstrap();

// // compiler
// new Vue({
//   el: '#app',
//   router: router,
//   template: '<App/>',
//   components: { App }
// })
