import request from "@/libs/utils/request";
import {QueryModel} from "@/libs/models";
import parse from '@/libs/utils/QueryParser';

export const getUser = (uid:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `user?uid=${uid}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const getUsers = (owner_id:string,query:QueryModel):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `users/${owner_id}/users${parse(query)}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const saveUsers=(uid:string,name:string,avatar?:string,owner_id?:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `users`,
        method: 'post',
        data:{
            uid,
            name,
            owner_id,
            avatar,
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
