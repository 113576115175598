import request from "@/libs/utils/request";
import {QueryModel} from "@/libs/models";
import parse from "@/libs/utils/QueryParser";

export const addGroupCare = (group_id:string,uid:string,name:string|null,avatar:string|null):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `groups/${group_id}/cares`,
        method: 'post',
        data:{
            uid,
            name,
            avatar,
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const getGroupCares=(group_id:string,query?:QueryModel):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `groups/${group_id}/cares${parse(query)}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const deleteGroupCare=(group_id:string,id:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `groups/${group_id}/cares/${id}`,
        method: 'delete',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
