import request from "@/libs/utils/request";
import {QueryModel} from "@/libs/models";
import parse from "@/libs/utils/QueryParser";

export const addGroup = (owner_id:string,use_for:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `groups`,
        method: 'post',
        data:{
            owner_id,
            use_for,
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const updateGroup = (id:string,group:any):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `groups/${id}`,
        method: 'put',
        data:group
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const getGroup=(id:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `groups/${id}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const getGroups=(owner_id:string,query?:QueryModel):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `groups${parse(query,`owner_id=${owner_id}`)}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const deleteGroup=(id:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `groups/${id}`,
        method: 'delete',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const addAuthsys = (owner_id:string,auth_url:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `authsys`,
        method: 'post',
        data:{
            owner_id,
            auth_url,
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const getAuthsys = (owner_id:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `authsys?owner_id=${owner_id}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
})

export const updateGroupTenantId = (id:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `groups_tenantid/${id}`,
        method: 'put',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
