<template>
  <span>
    <a-popover
        placement="topLeft"
        :visible="visible"
        trigger="click"
        arrow-point-at-center
    >
      <template slot="content"  >
        <div style="max-width: 360px;width: 80vw;">
          <img
            v-for="(v,i) in emojiList"
            :src="require(`../../../static/faces/${v}`)"
            :key="i"
            @click="selectEmoji(i)"
            class="img-style"
          />
        </div>
      </template>
      <a-icon :component="smailSvg" />
    </a-popover>
  </span>
</template>

<script>
import emoji from "../../config/emoji";
import SmailSvg from "@/components/svgs/SmailSvg";

export default {
  data() {
    return {
      smailSvg:SmailSvg,
      emojiList: emoji.obj,
      currentEmoji: "",
    };
  },
  methods: {
    selectEmoji(e) {
      let value = (this.inpMessage || "") + e;
      this.$data.showModal = false;
      this.$emit("selectEmoji", value);
    }
  },
  props: {
    inpMessage: String,
    visible:Boolean,
  },
  components:{SmailSvg}
};
</script>

<style scoped lang="less">
@import "../../style/index.less";
.img-style {
  width: 22px;
  margin: 5px;
  cursor: pointer;
}
.img-style:hover {
  background-color: @base;
}
</style>
