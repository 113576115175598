<template>
  <svg t="1640147516343" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="21875" width="32" height="32"><path d="M886 190.1H438.4L342 132.2c-6.6-4-14.2-6.1-22-6.1H135.1c-51.8 0-93.9 42.1-93.9 93.9v580.3c0 51.8 42.1 93.9 93.9 93.9H886c51.8 0 93.9-42.1 93.9-93.9V284c0-51.8-42.1-93.9-93.9-93.9z m-750.9 21.3h173.1l96.5 57.9c6.6 4 14.2 6.1 22 6.1H886c4.7 0 8.5 3.8 8.5 8.5v36h-768V220c0.1-4.7 3.9-8.6 8.6-8.6zM886 808.8H135.1c-4.7 0-8.5-3.8-8.5-8.5v-395h768v395c0 4.6-3.9 8.5-8.6 8.5z" p-id="21876"></path></svg>

</template>

<script>
export default {
  name: "FileSvg"
}
</script>

<style scoped>

</style>
