
<template>
	<div class="group">
    <a-spin :spinning="spinning" tip="Loading...">
      <el-card class="box-card">
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item :label="$t('care.use_for')">
            <el-input  size="mini" v-model="form.use_for"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button  size="mini"  plain type="primary" @click="onSubmit">{{$t("care.create")}}</el-button>
            <el-button size="mini"  plain @click="onCancel">{{$t("care.cancel")}}</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </a-spin>
	</div>
</template>

<script>
import "./index.less";
import { message } from 'ant-design-vue';
import {addGroup} from "@/libs/apis/group";

export default{
	data(){
		return {
      spinning:false,
      form: {
        use_for: 'default',
        // owner_id:this.$route.params.owner_id,
      }
		};
	},
	mounted: function(){
   if(this.owner_id===null||this.owner_id===undefined){
     message.error(this.$t("care.un_owner_id"))
     this.$router.back();
   }
	},
	components: {},
  computed: {
    owner_id:function (){
      let owner_id=this.$route.params.owner_id;
      if(!owner_id){
        owner_id=localStorage.getItem('owner_id');
      }
      return owner_id;
    }
  },
	methods: {
    onCancel(){
      this.$router.back();
    },
    async onSubmit() {
      try {
        this.spinning=true;
        await addGroup(this.owner_id,this.form.use_for);
        this.spinning=false;
        message.success(this.$t('care.opsSuccess'))
        this.$router.back();
      }catch (e) {
        this.spinning=false;
        message.error(e);
      }

    }
	},
};
</script>
