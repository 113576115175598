/**
 * 去除字符串首尾指定字符
 * @param str 要操作的字符串
 * @param char 要去除的字符
 * @param type 左，右，不传则首尾都去除
 */
export const trim = (str:string, char:string, type?:'left'|'right') :string => {
  if (char) {
    if (type === 'left') {
      return str.replace(new RegExp(`^\\${char}+`, 'g'), '');
    } if (type === 'right') {
      return str.replace(new RegExp(`\\${char}+$`, 'g'), '');
    }
    return str.replace(new RegExp(`^\\${char}+|\\${char}+$`, 'g'), '');
  }
  return str.replace(/^\s+|\s+$/g, '');
};

export const isJSON = (str:any) :boolean => {
  if (typeof str === 'string') {
    try {
      const obj = JSON.parse(str);
      if (typeof obj === 'object' && obj) {
        return true;
      }
      return false;
    } catch (e) { return false; }
  }
  return false;
};


export const dateFormat =(date:Date,format?: string): string => {
  const year = `${date.getFullYear()}`;
  let month = `${date.getMonth() + 1}`;
  if (month.length === 1) {
    month = `0${month}`;
  }
  let day = `${date.getDate()}`;
  if (day.length === 1) {
    day = `0${day}`;
  }

  let hours = `${date.getHours()}`;
  if (hours.length === 1) {
    hours = `0${hours}`;
  }
  let minutes = `${date.getMinutes()}`;
  if (minutes.length === 1) {
    minutes = `0${minutes}`;
  }
  let seconds = `${date.getSeconds()}`;
  if (seconds.length === 1) {
    seconds = `0${seconds}`;
  }

  return (format || "yyyy-MM-dd hh:mm:ss")
      .replace(/yyyy/g, year)
      .replace(/MM/g, month)
      .replace(/dd/g, day)

      .replace(/hh/g, hours)
      .replace(/mm/g, minutes)
      .replace(/ss/g, seconds);
}

export const dataURLToBlob=(dataurl:string) =>{
  const arr = dataurl.split(',')
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while(n--){
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], {type:mime})
}

export const fileOrBlobToDataURL=(obj:Blob):Promise<string> =>{
  return new Promise((resolve, reject)=>{
    const a = new FileReader()
    a.readAsDataURL(obj)
    a.onload = function (e){
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const rs=e.target.result as string;
      resolve(rs);
    }
  })

}
