import axios, { AxiosRequestConfig } from 'axios';
import { getAuthor } from '@/libs/utils/author';
import { getCareLang } from '@/libs/utils/langStore';
import url from '@/libs/utils/urlConfig';

const instance = axios.create();
instance.interceptors.request.use((config) => {
  // eslint-disable-next-line no-param-reassign
  if (!config.headers) config.headers = {};
  const author=getAuthor();
  const lang=getCareLang();
  if(author&&author!='null'){
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${author}`;
  }
  if(lang&&lang!='null'){
    // eslint-disable-next-line no-param-reassign
    config.headers.locale = lang;
  }
  return config;
}, (error) => Promise.reject(error));

instance.interceptors.response.use(((response) => {
  const data = response.data as any;
  return typeof (data) === 'string' ? JSON.parse(data) : data;
}),
({ response }) => {
  if (!response) {
    console.error(`接口'${instance.getUri()}'异常，请检查`);
  } else {
    console.error(response);
  }
  return Promise.reject(response);
});
const request = (config: AxiosRequestConfig) => {
  let config2={};
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if(config.url&&config.url.indexOf("http") !== 0){
    config2 = Object.assign(config, {
      url: url + config.url,
    });
  }else{
    config2 = config;
  }

  return instance.request(config2);
};
export default request;
