import request from "@/libs/utils/request";
import {QueryModel} from "@/libs/models";
import parse from "@/libs/utils/QueryParser";

export const addConnect = (group_id:string,care_uid:string,uid:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `connects`,
        method: 'post',
        data:{
            group_id,
            care_uid,
            uid,
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const getConnects=(group_id:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `connects?group_id=${group_id}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const getLatest=(group_id:string,sender_uid:string,receiver_uid:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `latestConnect?group_id=${group_id}&sender_uid=${sender_uid}&receiver_uid=${receiver_uid}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
