import LocalStore from "@/libs/utils/LocalStore";
import CryptoJS from "crypto-js";
import liveLocalStore from "@/libs/utils/LocalStore";
import {message} from "ant-design-vue";
import clientMixin from "@/mixins/clientMixin";

const uploadMixin = {
    methods:{
        /** 签名
         * args object类型 请求参数
         * token string类型 签名密钥
         **/
        makeSignature(args,token){
            var requestString="";
            // 字典排序
            var sorted= this.ksort(args);
            var keys = Object.keys(sorted);
            // 拼接参数，参数过滤file和sign，参数值用url编码一下
            keys.forEach((key)=>{
                if(sorted[key]&&['file','sign'].indexOf(key)==-1) requestString+=(key+'='+encodeURIComponent(sorted[key]));
            })
            // 转换为小写
            requestString=requestString.toLowerCase();
            // HmacMD5加密
            var sign=CryptoJS.HmacMD5(requestString,token)+"";
            return sign;
        },
        // 字典排序
        ksort(o) {
            let sorted = {},keys = Object.keys(o);
            keys.sort();
            keys.forEach((key)=>sorted[key] = o[key])
            return sorted;
        }
    },

}
export default uploadMixin;
