import request from "@/libs/utils/request";

export const ossUpload=(file:File):Promise<any> => new Promise((resolve, reject) => {
    // 在这里进行一系列的校验
    const formData = new FormData();
    formData.append("file",file);
    request({
        url: `oss/upload`,
        method: 'post',
        data:formData
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});