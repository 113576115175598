<template>
  <div :class="['messagebox',isPC()?'':'messagebox_mobile']"  v-show="toggleWindows" ref="messagebox" :style="{height:messageboxH}">

    <div class="messagebox-header" v-if="role=='care'" >
      <div>
        <a-icon
          type="left"
          class="user-goback"
          v-show="broken"
          @click="showUserList"
        />
        <span>{{destination?destination.name:""}}</span>
      </div>
    </div>

    <div :class="['messagebox-content',role!='care'?'messagebox-content-uncare':'']" ref="msgContent">
      <a-spin :spinning="spinning" tip="Loading..." class="content-spin">
        <div  class="moreMsgs" @click="loadMoreMsgs">
          <div class="more-inner">{{loadText}}</div>
        </div>
        <div
            v-for="(item, i) in msgList"
            :key="i"
            class="message-group"
            :style="{ float: (item.sender_uid==source.uid) ? 'right' : 'left' }"
        >
          <h4 style="text-align: left; margin: 0" v-if="(item.sender_uid!=source.uid)">{{item.sender?item.sender.name:''}}</h4>
          <div v-if="item.type==='system'" class="recallMsg">{{item.payload}}</div>
          <a-dropdown
              v-else
              :trigger="['contextmenu']"
              :style="{ float: (item.sender_uid==source.uid) ? 'right' : 'left' ,maxWidth: '48%'}"
              :disabled="true"
          >
          <span style="user-select: none">
            <!-- 图片消息 -->
            <div
                v-if="item.type === 'img'"
            >
               <el-image
                   :key="item.id"
                   fit="object-fit"
                   :src="item.payload ? JSON.parse(item.payload).url+'?x-oss-process=image/resize,h_140' : ''"
                   :preview-src-list="[JSON.parse(item.payload).url]"
                   class="img-style"
               />
            </div>
            <!-- 文件card -->
            <div
                v-else-if="item.type === 'file'"
                class="file-style"
                :style="{ float: (item.sender_uid==source.uid) ? 'right' : 'left' }">
              <a-card :body-style="{ padding: '0px' }">
                <div style="padding: 14px">
                  <h2>{{$t("care.file")}}</h2>
                  <span>
                    <h3>{{JSON.parse(item.payload).name}}</h3>
                  </span>
                  <div class="bottom clearfix">
                    <span>{{ readablizeBytes(JSON.parse(item.payload).size) }}</span>
                    <a-button type="link"  @click="download(JSON.parse(item.payload))">{{$t("care.download")}}</a-button>
                  </div>
                </div>
              </a-card>
            </div>
            <!-- 聊天消息 -->
            <p
                style="user-select: text"
                v-else
                v-html="renderTxt(item.payload)"
                :class="{ byself: (item.sender_uid==source.uid) }"
            />
          </span>
            <a-menu slot="overlay">
              <a-menu-item key="1" @click="handleCommand(item)">{{$t("care.recall")}}</a-menu-item>
            </a-menu>
          </a-dropdown>
          <!-- 聊天时间 -->
          <div
              v-if="item.type!=='system'"
              class="time-style"
              :style="{ 'text-align': (item.sender_uid==source.uid) ? 'right' : 'left' }"
          >
            {{ renderTime(item.created_at) }}
            {{ (item.state==1) ?$t("care.readed"): $t("care.unread") }}
          </div>
        </div>
      </a-spin>
    </div>
    <div class="messagebox-footer">
      <div class="footer-icon">
        <!-- 表情组件 -->
        <ChatEmoji @click.native="onShowEmoji" :visible="emoji_visible" v-on:selectEmoji="selectEmoji" :inpMessage="message" />
        <!-- 上传图片组件 -->
        <UpLoadImage
            @onImageMsg="onImageMsg"
            :rtmToken="rtmToken"
            :source="source"
            :destination="destination"
            :connect_id="connect_id_last"
            :group_id="group_id"
        />
        <!-- 上传文件组件 -->
        <UpLoadFile
            @onFileMsg="onFileMsg"
            :rtmToken="rtmToken"
            :source="source"
            :destination="destination"
            :connect_id="connect_id_last"
            :group_id="group_id"
        />
      </div>
      <div class="fotter-send">
        <a-textarea
          v-model="message"
          equired
          :placeholder="$t('care.msg')"
          class="sengTxt"
          @pressEnter="onSendTextMsg"
          style="resize: none"
          ref="txtDom"
        />
        <a-icon :component="sendSvg" @click="onSendTextMsg"/>
      </div>
    </div>
  </div>
</template>

<script>
import ChatEmoji from "../chatEmoji/index.vue";
import emoji from "../../config/emoji";
import UpLoadImage from "../upLoadImage/index.vue";
import UpLoadFile from "../upLoadFile/index.vue";
import SendSvg from "../svgs/SendSvg.vue";
import "./index.less";
import { isString } from "lodash";
import {one2oneMessages, readMessages, saveMessage} from "@/libs/apis/message";
import clientMixin from "@/mixins/clientMixin";
import {dateFormat, isJSON} from "@/libs/utils/utils";
import {Image, message} from "ant-design-vue";
import {getLatest} from "@/libs/apis/connect";
import {replayNotice} from "@/libs/apis/care";
import BadWords from "@/components/chat/BadWords";
import {getGroup} from "@/libs/apis/group";
import {msglog} from "@/libs/apis/sensitive";

// 表情base64数组
const emojiPathArr=[];
for (const em in emoji.obj) {
  const png=emoji.obj[em];
  emojiPathArr[png] =require(`../../../static/faces/${png}`)
}
export default{
	data(){
		return {
      sendSvg:SendSvg,
			message: "",
			// status: {
			// 	sending: "发送中",
			// 	sent: "已发送",
			// 	read: "已读",
			// },
			msgList: [],
      pageNumber:1,
      pageSize:10,
      hasMode:true,
      spinning:false,
      connect_id_last:null,
      destination:null,
      emoji_visible:false,
      messageboxH:"100%",
      replyTimer:null,
      group:null,
    };
	},
  beforeMount() {

  },
  mounted(){
    let u = navigator.userAgent;
    // let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
    let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    // if (isAndroid) {
    //   //这个是安卓操作系统
    //   alert('android')
    // }
    if (isIOS) {
      // alert('ios')
      this.messageboxH=(window.innerHeight - 50)+"px";
    }

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let that=this;
    this.onChangeDestination();
    const client=this.getClient();
    const listener=async function (message, peerId) {
      console.log(message, peerId)
      let msg=null;
      if(message.messageType==='IMAGE'){
        that.clearTimer();
        // 图片消息
        const blob = await client.downloadMedia(message.mediaId);
        const payload = await that.imgPayLoad(blob);
        msg={
          sender_uid:that.source.uid,//发送人
          payload:payload,// 消息负载 根据消息类型来解析
          type:'agora_img',// 消息类型
          state:0,// 消息状态 0 未读 1已读
          sender:that.source,
          created_at:dateFormat(new Date(),"yyyy-MM-dd hh:mm:ss"),
        };

      }else if(isJSON(message.text)){
        msg= JSON.parse(message.text);
        if (msg.type=='agora_file'){
          that.parseAgoraFileMsg(msg);
        }
        if(msg.type!='action'){
          that.clearTimer();
        }
      }
      if(msg){// peerId==that.destination.uid&&
        if(msg.type!='action'){
          if(that.role!=='care'||peerId==that.destination.uid){
            msg.sender.name=msg.sender.username
            msg.sender.avatar=msg.sender.picture
            that.msgList.push(msg);
            that.readerReply();
            that.scollBottom();
          }
        }
        if(peerId==that.destination.uid) {
          that.changeAllMsgState();
          setTimeout(()=>that.onReadMessages(),2000);
        }
      }
      if(msg&&msg.type==='action'&&msg.payload&&isJSON(msg.payload)){
        const payload= JSON.parse(msg.payload);
        if(payload.action==="care_change"){
          const newDestination=JSON.parse(payload.payload);
          if(newDestination.uid!==that.destination.uid&&that.destinationChange){
            that.destinationChange({...newDestination, is_care:true,});
          }
          that.destination=newDestination;
        }
      }
      that.$emit('onMessageFromOther',msg,peerId)
    };
    client.on('MessageFromPeer',listener );
    if(!this.group){
      getGroup(this.group_id).then(group=>{
        this.group=group;
        if(this.group&&this.group.tenant_id) {
          BadWords.getInstance(this.group.tenant_id);
        }
      });
    }

	},
	computed: {
		// 控制聊天框
		toggleWindows(){
			return true;
		},
    loadText(){
      return this.hasMode?this.$t("care.loadMore"):this.$t("care.noMore");
    },

	},
  watch:{
    destination2(){
      if(this.destination&&this.destination.uid==this.destination2.uid) return;
      this.msgList=[];
      this.pageNumber=1;
      this.hasMode=true;
      this.$nextTick(async ()=>{
        this.onChangeDestination();
      });
    }
  },
	props: [
		"broken", // 是否适应移动端
		"showUserList",
		"hideUserList",
    "destinationChange",
    "source",
    "destination2",
    "rtmToken",
    "group_id",
    "time_limit",
    "connect_id",
    "role",
	],
	methods: {
    clearTimer(){
      if(this.replyTimer!=null){
        clearTimeout(this.replyTimer);
        console.log("已停止timeout")
      }
    },
    isPC() {
      const userAgentInfo = navigator.userAgent;
      const Agents = ["Android", "iPhone",
        "SymbianOS", "Windows Phone",
        "iPad", "iPod"];
      let flag = true;
      for (let v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return flag;
    },
    download(rs){
      let anchor = document.createElement("a");
      anchor.download = rs.name;
      anchor.href = rs.url+ '?response-content-type=application/octet-stream';
      anchor.target ="_blank";
      anchor.style.display = "none"; // just to be safe!
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    },
    onShowEmoji(){
      this.emoji_visible=true
    },
    async getConnectId(){
      const connect=await getLatest(this.group_id,this.source.uid,this.destination.uid);
      if(this.role!=='care'&&this.destinationChange&&connect.care&&connect.care.uid!==this.destination.uid){
        this.destinationChange({
          username:connect.care.name,
          picture:connect.care.avatar,
          uid:connect.care.uid,
          is_care:true,
        });
      }
      return connect.id;
    },
    // async parseAgoraFileMsg(msg){
    //   if(msg.type==='agora_file'&&isJSON(msg.payload)){
    //     const payload=JSON.parse(msg.payload);
    //     msg.payload=await this.loadAgoraFile(payload.mediaId);
    //     msg.fileSize=payload.size;
    //     msg.fileName=payload.name;
    //   }
    // },
    // async loadAgoraImg(mediaId){
    //   try {
    //     const client=this.getClient();
    //     // 图片消息
    //     const blob = await client.downloadMedia(mediaId);
    //     const payload = await this.imgPayLoad(blob);
    //     return payload;
    //   }catch (e) {
    //     return null;
    //   }
    //
    // },
    // async loadAgoraFile(mediaId){
    //   try {
    //     const client=this.getClient();
    //     const blob = await client.downloadMedia(mediaId);
    //     return  URL.createObjectURL(blob)
    //   }catch (e) {
    //     return null;
    //   }
    // },
    async onImageMsg(msg){
      // msg.payload= await this.loadAgoraImg(msg.payload);
      msg.hadParse=true;
      this.msgList.push(msg);
      this.replyTimeoutIntval();
      this.scollBottom();

    },
    async onFileMsg(msg){
      // msg.payload= await this.loadAgoraFile(msg.payload);
      // await this.parseAgoraFileMsg(msg);
      msg.hadParse=true;
      this.msgList.push(msg);
      this.replyTimeoutIntval();
      this.scollBottom();
    },
    async onChangeDestination(){
      this.destination=this.destination2
      const connect_id=await this.getConnectId()
      this.connect_id_last=connect_id;
      // this.connect_id_last=this.connect_id
      await this.loadMoreMsgs()
      this.scollBottom();
      this.onReadMessages();
      this.$emit('onMessageFromOther',null,this.destination.uid);
      this.readerReply();
    },
    async onReadMessages(){

      await readMessages(this.connect_id_last,this.source.uid);
      for (let i = 0; i <this.msgList.length ; i++) {
        if(this.source.uid!=this.msgList[i].sender_uid)this.msgList[i].state=1;
      }
    },
    changeAllMsgState(){
      for (let i = 0; i <this.msgList.length ; i++) {
        this.msgList[i].state=1;
      }
    },
    readerReply(){

      const client=this.getClient();
      if(client){
        // 告诉对方消息已读
        const msg_reply = {
          sender_uid:this.source.uid,//发送人
          payload: JSON.stringify({
            'action': 'read',
            'payload': '',
          }),// 消息负载 根据消息类型来解析
          type: 'action',// 消息类型
          state: 0,// 消息状态 0 未读 1已读
          sender:this.source,
          created_at: dateFormat(new Date(), "yyyy-MM-dd hh:mm:ss"),
        }
        const message = {text: JSON.stringify(msg_reply)};
        client.sendMessageToPeer(message, this.destination.uid)
      }
    },
    // eslint-disable-next-line no-mixed-spaces-and-tabs
	  async	loadMoreMsgs(){
      if(!this.hasMode) return;
      this.spinning=true;
      let that=this;
      const rs= await one2oneMessages(this.group_id,this.connect_id_last,{
        include:['sender'],
        page:{
          number:this.pageNumber++,
          size:this.pageSize,
        }
      });
      this.spinning=false;
      this.hasMode=rs.data.length==this.pageSize;
      async function parseMsgs(data) {
        for (let i = data.length-1; i >0; i--) {
          if(data[i].hadParse||(['agora_img','agora_file'].indexOf(data[i].type)==-1)) continue;
          if(data[i].type=='agora_img'){
            data[i].payload=await that.loadAgoraImg(data[i].payload)
          }
          if(data[i].type=='agora_file'){
            await that.parseAgoraFileMsg(data[i]);
          }
          data[i].hadParse=true;
        }
      }
      let data= rs.data.reverse();
      this.msgList=data.concat(this.msgList);
      parseMsgs(this.msgList);
		},
		// 右侧菜单
		rightMenus(){

		},
		menuClick(i){

		},
		async onSendTextMsg(){
			if(this.$data.message == "" || this.$data.message == "\n"){
				this.$data.message = "";
				return;
			}

      if(!this.group){
        this.group=await getGroup(this.group_id);
      }
      if(this.group&&this.group.tenant_id){
        const badWords=BadWords.getInstance(this.group.tenant_id);
        const { result, word } = badWords.filter(this.$data.message);
        if (!result) {
          message.warning(`发送失败，您的消息含有敏感词'${word}'`);
          if(badWords.instanzeData?.id){
            msglog(badWords.instanzeData?.id,word,this.$data.message);
          }
          return;
        }
      }

			// 发送消息
      const client=this.getClient();
      if(client){
        const msg= {
          sender_uid:this.source.uid,//发送人
          payload:this.$data.message,// 消息负载 根据消息类型来解析
          type:'text',// 消息类型
          state:0,// 消息状态 0 未读 1已读
          sender:this.source,
          created_at:dateFormat(new Date(),"yyyy-MM-dd hh:mm:ss"),
        }
        const message={text: JSON.stringify(msg)};
        this.msgList.push(msg)
        await saveMessage(this.source.uid,
            this.destination.uid,
            msg.payload,
            msg.state,
            msg.type,
            this.group_id,
            this.connect_id_last
      );
      client.sendMessageToPeer(message,this.destination.uid)
      }
			this.$data.message = "";
      this.scollBottom();
      if(this.source.is_care){
        const msg= {
          sender_uid:this.source.uid,//发送人
          payload: JSON.stringify({
            'action': 'care_change',
            'payload': JSON.stringify(this.source),
          }),// 消息负载 根据消息类型来解析
          type:'action',// 消息类型
          state:1,// 消息状态 0 未读 1已读
          sender:this.source,
          created_at:dateFormat(new Date(),"yyyy-MM-dd hh:mm:ss"),
        }
        const message={text: JSON.stringify(msg)};
        client.sendMessageToPeer(message,this.destination.uid)
      }
      this.replyTimeoutIntval();
		},
    replyTimeoutIntval(){
      if(this.role!=='care'){
        if(this.replyTimer===null){
          let that=this;
          this.replyTimer=setTimeout(function (){
            replayNotice(that.group_id,that.source.uid).then((_data)=>{
              if(_data&&_data.res===1) {
                message.success(this.$t("care.wait"));
              }else{
                message.warning(this.$t("care.careOffline"));
              }
            })
          },that.time_limit*1000);
        }
      }
    },
		selectEmoji(v){
      this.emoji_visible=false;
			this.$data.message = v;
			this.$refs.txtDom.focus();
		},

		customEmoji(value){
			// return `<img src="../../../static/faces/${value}" style="width:20px"/>`;
      const src=emojiPathArr[value];
      return `<img src="${src}" style="width:20px"/>`.replace(/,/g, "FACE_SEPARATOR");
		},

		renderTxt(txt = ""){
			let rnTxt = [];
			let match = null;
			const regex = /(\[.*?\])/g;
			let start = 0;
			let index = 0;
			while((match = regex.exec(txt))){
				index = match.index;
				if(index > start){
					rnTxt.push(txt.substring(start, index));
				}
				if(match[1] in emoji.obj){
					const v = emoji.obj[match[1]];
					rnTxt.push(this.customEmoji(v));
				}
				else{
					rnTxt.push(match[1]);
				}
				start = index + match[1].length;
			}
			rnTxt.push(txt.substring(start, txt.length));
			return  rnTxt.toString().replace(/,/g, "").replace(/FACE_SEPARATOR/g, ",")

		},
		readablizeBytes(value){
      if(!value) return '未知大小';
			let s = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
			let e = Math.floor(Math.log(value) / Math.log(1024));
			return (value / Math.pow(1024, Math.floor(e))).toFixed(2) + " " + s[e];
		},

		// TODO 可以抽离到utils
		renderTime(time){
			if(isString(time)){
				return time;
			}
			const t = new Date(parseInt(time));
			var Y = t.getFullYear();
			var M =
        t.getMonth() + 1 < 10 ? "0" + (t.getMonth() + 1) : t.getMonth() + 1;
			var D = t.getDate() < 10 ? "0" + t.getDate() : t.getDate();
			var H = t.getHours() < 10 ? "0" + t.getHours() : t.getHours();
			var F = t.getMinutes() < 10 ? "0" + t.getMinutes() : t.getMinutes();
			var S = t.getSeconds() < 10 ? "0" + t.getSeconds() : t.getSeconds();
			return `${M}-${D} ${H}:${F}`;
		},

		scollBottom(){
			setTimeout(() => {
				const dom = this.$refs.msgContent;
				if(!dom) return;
				dom.scrollTop = dom.scrollHeight;
			}, 0);
		},
		handleCommand(item){

		},

	},
	components: {
		ChatEmoji,
		UpLoadImage,
		UpLoadFile,
    SendSvg,
	},
  mixins:[clientMixin],
};
</script>

<style scoped lang="less">
@import "../../style/index.less";
.byself {
  background-color: #eceff1;
  float: right;
}
.recallMsg {
  font-size: 12px;
  color: #aaa;
  width: 100%;
  text-align: center;
}
.custom-title {
  font-weight: 500;
}
.moreMsgs {
  height: 30px;
  line-height: 30px;
  .more-inner{
    min-width: 150px;
    background: @base;
    color: white;
    border-radius: 15px;
    cursor: pointer;
    display: inline-block;
    padding: 0px 20px;
    transition: all 0.5s linear;
  }
}
.status {
  display: inline;
  position: relative;
  top: 20px;
  font-size: 12px;
  left: -6px;
  color: #736c6c;
  float: left;
}
.unreadNum {
  float: left;
  width: 100%;
}
.icon-style {
  display: inline-block;
  background-color: #f04134;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: white;
  line-height: 1.5;
  text-align: center;
}
.emoji-style {
  width: 22px;
  float: left;
}
.img-style {
  max-width: 350px;
  width: 200px;
  height: 200px;
}
.time-style {
  clear: both;
  margin-left: 2px;
  margin-top: 3px;
  font-size: 12px;
  color: #888c98;
}
.file-style {
  width: 240px;
  margin: 2px 2px 2px 0;
  font-size: 13px;
  h2 {
    border-bottom: 1px solid #e0e0e0;
    font-weight: 300;
    text-align: left;
  }
  h3 {
    max-width: 100%;
    font-size: 15px;
    height: 20px;
    line-height: 20px;
    font-weight: 600;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    margin-bottom: 20px;
  }
  .bottom {
    span {
      color: #999999;
      text-align: left;
    }
  }
  a {
    color: #999999;
    float: right;
    text-decoration: none;
  }
  .el-dropdown-link {
    cursor: pointer;
    color: #409eff;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
}
.fotter-send{
  //background-color: #42b983;
  //position: relative;
  .anticon{
    position: absolute;
    height: 100%;
    line-height: 100%;
    right: 15px;
    margin-right: 8px;
    font-size: 20px;
  }
}
.messagebox-content{
  .content-spin{
    height: 100%;
  }
  &::-webkit-scrollbar { width: 0 !important }
}

</style>
