<template>
  <svg t="1640147087261" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2713" width="32" height="32"><path d="M512 512m-393.846154 0a393.846154 393.846154 0 1 0 787.692308 0 393.846154 393.846154 0 1 0-787.692308 0Z" fill="#BFBFBF" p-id="2714"></path><path d="M469.464615 469.464615m-351.310769 0a351.310769 351.310769 0 1 0 702.621539 0 351.310769 351.310769 0 1 0-702.621539 0Z" fill="#FFFFFF" p-id="2715"></path><path d="M354.461538 615.975385a100.036923 78.769231 90 1 0 157.538462 0 100.036923 78.769231 90 1 0-157.538462 0Z" p-id="2716"></path><path d="M352.098462 335.556923m-59.076924 0a59.076923 59.076923 0 1 0 118.153847 0 59.076923 59.076923 0 1 0-118.153847 0Z" p-id="2717"></path><path d="M623.852308 372.578462m-59.076923 0a59.076923 59.076923 0 1 0 118.153846 0 59.076923 59.076923 0 1 0-118.153846 0Z" p-id="2718"></path><path d="M512 937.353846c-234.732308 0-425.353846-190.621538-425.353846-425.353846s190.621538-425.353846 425.353846-425.353846 425.353846 190.621538 425.353846 425.353846-190.621538 425.353846-425.353846 425.353846z m0-787.692308c-200.073846 0-362.338462 162.264615-362.338462 362.338462s162.264615 362.338462 362.338462 362.338462 362.338462-162.264615 362.338462-362.338462-162.264615-362.338462-362.338462-362.338462z" p-id="2719"></path></svg>

</template>

<script>
export default {
  name: "SmailSvg"
}
</script>

<style scoped>

</style>
