<template>
    <div class="userlist">
      <a-spin :spinning="spinning" tip="Loading...">
        <a-menu style="width: 100%; border-right: 0;min-height: 80vh" mode="vertical">
        <a-menu-item
            style="height: 80px; position: relative; textAlign: left; borderBottom: 1px solid #eee; margin: 0"
            v-for="(item) in userList"
            :key="item.uid"
            @click="select2(item)"
        >
          <div>
            <img class="friend_portrait" :src="item.avatar?item.avatar:headPortraitImg" alt="">
            <span class="custom-title" >{{ item.nickname || item.name}}</span>
          </div>
          <span class="custom-title">{{item.recently_msg?item.recently_msg.payload:''}}</span>
          <div class="icon-style" v-if="item.unReadSum > 0">
            <span class="unreadNum">{{item.unReadSum}}</span>
          </div>
          <span class="time-style" style="float:right">{{item.recently_msg?item.recently_msg.created_at:''}}</span>
          <div>{{item.recently_msg?item.recently_msg.payload:''}}</div>
        </a-menu-item>
      </a-menu>
      </a-spin>
    </div>
</template>

<script>
import "./index.less";
import clientMixin from "@/mixins/clientMixin";
import {addConnect, getConnects} from "@/libs/apis/connect";

export default {
  data() {
    return {
      headPortraitImg:require('../../assets/headPortrait.png'),
      isCollapse: true,
      userList:[],
      onlineState:[],
      spinning:false,

    };
  },
  beforeMount() {

  },
  mounted() {
    this.spinning=true;
    this.init();
    setInterval(()=>this.getUserList(),5000);
  },
  computed: {
    uids(){
      return this.userList.map(user=>user.uid);
    },
  },
  props: [
    "select",
    "uid",
    "rtmToken",
    "selectUser",
    "group_id",
  ],
  methods: {
    async init(){
      await this.getUserList();
    },
    async onMessageFromOther(msg,send_uid){
      console.log('onMessageFromOther',msg,send_uid)
      // let index=this.userList.findIndex((user)=>user.uid==send_uid);
      // if(index>-1){
      //   // 当前对话人，最新消息修改为已读
      //   if(this.selectUser&&this.selectUser.uid==send_uid) {
      //     this.userList[index].msg_state=1;
      //   }else{
      //     msg&&(this.userList[index].msg_state=msg.state);
      //   }
      //   // 替换最新消息
      //   if(msg&&msg.type!='action') {
      //     this.userList[index].recently_msg=msg;
      //   }
      //   this.parseRecentlyMsg(this.userList);
      //   return;
      // }
      if(msg&&msg.type==='action'){
        return
      }
      await this.getUserList();
    },
    parseRecentlyMsg(userList){
      // console.log('parseRecentlyMsg',userList)
      for (let i = 0; i < userList.length; i++) {
        const msg=userList[i].recently_msg
        if(!msg||(['agora_img','agora_file','img','file'].indexOf(msg.type)==-1)) continue;
        let recently_msg={}
        if(msg.type=="agora_img"||msg.type=="img") recently_msg={...msg,payload:'[图片]'};
        if(msg.type=="agora_file"||msg.type=="file") recently_msg={...msg,payload:'[文件]'};
        recently_msg.hadParse=true;
        console.log('recently_msg',recently_msg)
        userList[i].recently_msg=recently_msg;
      }
      return userList;
    },
    async select2(key, index) {
      this.select(key);
    },
    async getUserList(){
      const rs=await getConnects(this.group_id);
      rs.data=this.parseRecentlyMsg(rs.data);
      this.userList=rs.data;
      this.spinning=false;
      this.$nextTick(()=>this.queryonLine());
    },
  },
  mixins:[clientMixin],
};
</script>

<style scoped lang='less'>
.userlist {
  height: 100%;
  overflow-y: scroll;
  border-right: 1px solid #e8e8e8;
  &::-webkit-scrollbar { width: 0 !important }
}
.byself {
  float: right;
}
.recallMsg {
  font-size: 12px;
  color: #aaa;
  width: 100%;
  text-align: center;
}
.friend_portrait{
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
.custom-title {
  font-weight: 500;
  width: 100%;
  height: 50px;
  padding-left: 5px;
}
.moreMsgs {
  background: #ccc !important;
  border-radius: 8px;
  cursor: pointer;
}
.status {
  display: inline;
  position: relative;
  top: 20px;
  font-size: 12px;
  left: -6px;
  color: #736c6c;
}
.unreadNum {
  float: left;
  width: 100%;
}
.icon-style {
  position: absolute;
  right: 10px;
  top: 25%;
  display: inline-block;
  background-color: #f04134;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: white;
  line-height: 1.5;
  text-align: center;
}
.emoji-style {
  width: 22px;
  float: left;
}
.img-style {
  max-width: 350px;
}
.time-style {
  clear: both;
  margin-left: 2px;
  margin-top: 3px;
  font-size: 12px;
  color: #888c98;
  position: absolute;
  right: 5px;
}
.file-style {
  width: 240px;
  margin: 2px 2px 2px 0;
  font-size: 13px;
  h2 {
    border-bottom: 1px solid #e0e0e0;
    font-weight: 300;
    text-align: left;
  }
  h3 {
    max-width: 100%;
    font-size: 15px;
    height: 20px;
    line-height: 20px;
    font-weight: 600;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    margin-bottom: 20px;
  }
  .bottom {
    span {
      color: #999999;
      text-align: left;
    }
  }
  a {
    color: #999999;
    float: right;
    text-decoration: none;
  }
  .el-dropdown-link {
    cursor: pointer;
    color: #409eff;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }

  .el-menu-vertical-demo {
    width: 100%;
  }
}
.ant-spin-dot {
  margin-top: 40px;
}
</style>
