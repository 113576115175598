import request from "@/libs/utils/request";

export const getCare = ():Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `care_user`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const saveCare=(uid:string,name:string,avatar?:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `care_user`,
        method: 'post',
        data:{
            uid,
            name,
            avatar:avatar
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const careBelong = (group_id:string,uid:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `belong/${group_id}/cares/${uid}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const addmail = (group_id:string,email:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `email/${group_id}/add`,
        method: 'post',
        data:{
            email
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const delmail = (group_id:string,email:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `email/${group_id}/del`,
        method: 'post',
        data:{
            email
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const replayNotice = (group_id:string,uid:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `replay_notice/${group_id}/${uid}`,
        method: 'post',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
