
<template>
  <div>
    <a-spin :spinning="spinning" tip="Loading...">
      <div class="admin" v-if="inited">

        <div class="header">
          <el-button size="mini"  plain type="primary" icon="el-icon-refresh"  @click="getList()"></el-button>
          <el-button size="mini"  plain type="primary" icon="el-icon-plus"  @click="handleAdd()">{{$t("care.addGroup")}}</el-button>

        </div>
        <div class="content">
          <a-modal v-model="visible">
            <template #footer>
              <a-button key="back" @click="visible=false">{{$t("care.cancel")}}</a-button>
              <a-button key="submit" type="danger" :loading="deleteLoading"  @click="handleOk">{{$t("care.delete")}}</a-button>
            </template>
            <span>{{$t("care.deleteConfirm")}}？</span>
          </a-modal>
          <el-empty v-if="tableData.length===0"></el-empty>
          <el-table
              v-else
              class="table"
              :data="tableData"
              :height="tableHeight"
              highlight-current-row
          >
            <el-table-column
                prop="id"
                :label="$t('care.groupId')"
            >
              <template slot-scope="scope">
                <div class="avatar">
                  {{scope.row.id}}
                  <a-tag @click="handleLink(scope.$index, scope.row)">{{$t("care.copy")}}</a-tag>

                </div>
              </template>
            </el-table-column>
            <el-table-column
                prop="emails"
                :label="$t('care.mail')"
            >
              <template slot-scope="scope">
                <div class="emails">
                  <el-tag style="margin-bottom: 5px;" @close="onDelMail(scope.row,email.email)" effect="plain" type="danger" v-for="email in scope.row.emails" :key="email.id" size="small" closable>{{email.email}}</el-tag>
                 <div>
                   <el-button type="primary" size="mini" plain @click="handleAddMail(scope.row)">{{$t("care.add")}}</el-button>
                 </div>

                </div>
              </template>
            </el-table-column>
            <el-table-column
                prop="time_limit"
                :label="$t('care.timeout')"
            >
              <template slot-scope="scope">
                <el-input-number :min="10" v-model="scope.row.time_limit" :step="10" @change="changeLimit(scope.row)"></el-input-number>
              </template>
            </el-table-column>
            <el-table-column
                prop="use_for"
                :label="$t('care.use_for')"
            >
            </el-table-column>
            <el-table-column
                prop="created_at"
                :label="$t('care.created_at')"
            >
            </el-table-column>
            <el-table-column
                :label="$t('care.ops')"
            >
              <template slot-scope="scope">
                <div class="options">
                  <div>
                    <el-button
                        :title="$t('care.deleteGroup')"
                        size="mini"
                        type="danger" plain
                        icon="el-icon-delete"
                        @click="handleDelete(scope.$index, scope.row)"></el-button>
                  </div>
                  <div>
                    <el-button
                        :title="$t('care.manageCare')"
                        size="mini"
                        type="primary" plain
                        icon="el-icon-setting"
                        @click="handleEdit(scope.$index, scope.row)">{{$t("care.manageCare")}}</el-button>
                  </div>
                  <div>
                    <el-button
                        :title="$t('care.copyCustom')"
                        size="mini"
                        type="primary" plain
                        icon="el-icon-link"
                        @click="handleCustomLink(scope.$index, scope.row)">{{$t("care.copyCustom")}}</el-button>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <input v-model="copy_txt" type="text"  id="copy_dom" v-show="copying">
    </a-spin>
    <el-dialog width="350px" :modal="false" :title="$t('care.addMail')" center :visible.sync="dialogAddMailVisible">
      <el-form >
        <el-form-item :label="$t('care.mail')">
          <el-input v-model="email"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogAddMailVisible = false">{{$t("care.cancel")}}</el-button>
        <el-button type="primary" @click="addMail">{{$t("care.add")}}</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import "./index.less";
import { message } from 'ant-design-vue';
import {
  addAuthsys,
  addGroup,
  deleteGroup,
  getAuthsys,
  getGroups,
  updateGroup,
  updateGroupTenantId
} from "@/libs/apis/group";
import liveLocalStore from "@/libs/utils/LocalStore";
import {setAuthor} from "@/libs/utils/author";
import clientMixin from "@/mixins/clientMixin";
import linkMixin from "@/mixins/linkMixin";
import {getGroupCares} from "@/libs/apis/groupcare";
import jwt_decode from "jwt-decode";
import {addmail, delmail} from "@/libs/apis/care";
import {setCareLang} from "@/libs/utils/langStore";

export default{
	data(){
		return {
      inited:false,
      deleteLoading:false,
      visible:false,
      spinning:false,
      owner_id:43,
      tableHeight:document.documentElement.clientHeight - 100,
      tableData:[],
      deleteRow:{},
      auth_url:'',
      dialogAddMailVisible:false,
      handerRow:null,
      email:'',
      userid:null,
		};
	},
	mounted: function(){
    const args=this.getUrlArgObject();
    const sign=this.makeSignature(args,'dfoptkf9rj4jh58dfy67ft98t60fguj');
    console.log("参数",args,sign)
    if(args.sign!==sign){
      message.error(this.$t('care.errSign')).then(()=>{
        this.postMsg('init_err',this.$t('care.errSign'))
      });
      return;
    }
    // if(!args.auth_url){
    //   message.error('未设置令牌站点');
    //   this.postMsg('init_err','未设置令牌站点')
    //   return
    // }
    // if(!args.owner_id){
    //   message.error('未设置拥有者');
    //   this.postMsg('init_err','未设置拥有者')
    //   return
    // }
    if(!args.Authorization){
      message.error(this.$t('care.Authorization'));
      this.postMsg('init_err',this.$t('care.Authorization'))
      return
    }
    const locale=args?.locale??this.$i18n.locale;
    this.$i18n.locale=locale;
    setCareLang(locale);
    this.owner_id=args.owner_id;
    this.inited=true;
    // this.auth_url=args.auth_url;
    // addAuthsys(this.owner_id,this.auth_url);
    // localStorage.setItem('auth_url',this.auth_url);
    setAuthor(args.Authorization);
    try {
      const decoded=jwt_decode(args.Authorization)
      this.userid=decoded.sub;
    }catch (e) {
      message.warning(this.$t('care.tokenTimeout')).then(()=>{
        this.postMsg('init_err',this.$t('care.tokenTimeout'))
      })
    }
    if(this.owner_id==null){
      this.owner_id=this.userid;
    }
    localStorage.setItem('owner_id',this.owner_id);
    this.$nextTick(function () {
      // 监听窗口大小变化
      let self = this;
      window.onresize = function() {
        self.tableHeight = document.documentElement.clientHeight - 100;
      }
    });
    this.getList();

	},
	components: {},
	computed: {
	},
	methods: {
    // async getAuthsys(){
    //   try {
    //     const rs= await getAuthsys(this.owner_id);
    //     this.auth_url=rs.url;
    //   }catch (e) {
    //     message.error(e.data.message);
    //   }
    // },
    async changeLimit(group){
        await  updateGroup(group.id,group);
    },
    async onDelMail(row,email){
      await delmail(row.id,email);
      message.success(this.$t('care.opsSuccess'))
      this.getList();
    },
    async addMail(){
      if(!this.handerRow) {
        message.error(this.$t('care.choiceGroup'))
        this.dialogAddMailVisible=false;
        return;
      }
      try {
        await addmail(this.handerRow.id,this.email);
      }catch (res) {
        message.error(res.data.message)
        return;
      }

      message.success(this.$t('care.opsSuccess'))
      this.dialogAddMailVisible=false;
      this.email=null;
      this.getList();
    },
    handleAddMail(row){
      this.handerRow=row;
      this.dialogAddMailVisible=true;
    },
    async handleOk(){
      try {
        this.deleteLoading=true;
        await deleteGroup(this.deleteRow.id)
        this.deleteLoading=false;
        this.visible=false;
        message.success(this.$t('care.opsSuccess'));
        this.getList();
      }catch (e) {
        message.error(e);
      }
    },
    async getList(){
      let that=this;
      this.spinning=true;
      let owner_id=this.owner_id;
      const rs=await getGroups(owner_id,{
        append:['emails'],
      });
      this.tableData= rs.data;
      // 旧数据补充租户id
      for (let i = 0; i < rs.data.length; i++) {
        const group=rs.data[i];
        if(group.tenant_id===null){
          updateGroupTenantId(group.id);
        }
      }
      if(!this.tableData||this.tableData.length===0){
        this.$confirm(this.$t('care.addGroupConfirm'), this.$t('care.tips'), {
          confirmButtonText: this.$t('care.add'),
          cancelButtonText: this.$t('care.cancel'),
          type: 'warning'
        }).then(async () => {
          await addGroup(this.owner_id,'default');
          message.success(this.$t('care.opsSuccess'));
          that.getList()
        });
      }
      this.spinning=false;
    },
    handleCustomLink(index,row){
      const url= this.createCustomLink(row.id);
      this.postMsg('custom_link',JSON.stringify({
        url,
      }))
      this.copy(url);
    },
    async handleLink(index,row){
      const rs=await getGroupCares(row.id);
      if(rs.data.length==0){
        message.warning(this.$t('care.setCare'))
        return;
      }
      this.postMsg('group',JSON.stringify({
        id:row.id,
      }))
      this.copy(row.id);
    },
    handleAdd(){
      this.$router.push({ name: 'addgroup', params: { owner_id: this.owner_id }});
    },
    handleEdit(index,row){
      localStorage.setItem('group_id',row.id);
      this.$router.push({ name: 'carelist', params: { owner_id: this.owner_id,group_id: row.id }});
		},
    goSensitive(){
      this.$router.push({ name: 'sensitive', params: { group_id: this.tableData[0].id }});
    },
    handleDelete(index,row){
      this.visible=true;
      this.deleteRow=row;
    },
	},
  mixins:[clientMixin,linkMixin],
};
</script>
