<template>
  <svg t="1640147678018" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="23554" width="32" height="32"><path d="M0 524.8l281.6 166.4 582.4-531.2L384 704l384 128 256-832zM384 985.6L512 832l-128-64z"  p-id="23555"></path></svg>
</template>

<script>
export default {
  name: "SendSvg"
}
</script>

<style scoped>

</style>
