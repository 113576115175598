<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>

export default{
  name: "App"
};

</script>
<style lang="less">
@import url('./utils/theme/base.css');

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.el-message-box{
  width: 50%;
  max-width: 400px;
}
</style>
