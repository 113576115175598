import {LangSdk} from "topkee-lang-service-jssdk";
import VueI18n from "vue-i18n";
import {appid, appsecret} from "./langConf";

// 加载所有语言环境并记住上下文
const  loadMessages=()=> {
    let messages={};
    if(require){
        const context = require.context("./lang-upload", false, /[a-z0-9-_]+\.(js|json|ts)$/i);

        messages = context
            .keys()
            .map((key) =>{
                const reg=key.match(/[a-z0-9-_]+/i);
                return  { key, locale: (reg?.shift() as string) }
            })
            .reduce(
                (messages, { key, locale }) => ({
                    ...messages,
                    [locale]: context(key),
                }),
                {}
            );
    }else{
        console.warn('require&&require.context is null 未加载到本地语言')
    }
    return messages;
}
const messages=loadMessages();

// const codes=Object.keys(messages);
let local='zh_TW';
if(localStorage?.getItem('language')){
    local=localStorage.getItem('language') as string;
}





//注册i8n实例并引入语言文件
// const i18n = createI18n({
//     fallbackLocale: local,//预设语言环境
//     globalInjection:true,
//     legacy:false,
//     locale: local,		//默认显示的语言
//     messages:langSdk.getMessages()
// })
// langSdk.init();
// export default i18n; //将i18n暴露出去，在main.js中引入挂载
async function initLangSdk(i18n:VueI18n){
    try {
        const langSdk=new LangSdk(appid, appsecret,(_local, _messages)=>{
            i18n.setLocaleMessage(_local, _messages)
        });
        await langSdk.init();
        await langSdk.loadLocalesMessages(messages);
    } catch (e) {
        console.error('初始化langsdk异常',e);
    }
}
export async function setupI18n() {
    const i18n = new VueI18n({
        fallbackLocale: 'en',//预设语言环境
        locale: local,		//默认显示的语言
        messages:messages
    })
    initLangSdk(i18n);
    return i18n;


}