import {exportSensitiveWord, getInstance} from "@/libs/apis/sensitive";

export default class BadWords {
  private static _instance: BadWords;

  private data: Array<string> = [];

  public instanzeData:any =null;

  constructor(x_tenant:string) {
    getInstance(x_tenant).then(rs=>{
      if(rs){
        this.instanzeData=rs;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        exportSensitiveWord(rs.id).then((rs) => {
          this.data = rs as Array<string>;
          console.log('敏感词', this.data);
        });
      }
    });

  }

  public static getInstance(x_tenant:string) {
    if (!BadWords._instance) {
      BadWords._instance = new BadWords(x_tenant);
    }
    return BadWords._instance;
  }

  /**
   * @param content
   */
  public filter(content: string) {
    const tempImgs: Array<string> = [];
    // let result = content;
    let result = true;

    // // 将图片部分放入临时数组中，以避免待会儿过滤词会匹配到图片内容
    // content = content.replace(/!\[.+\]\(.+\)/g, (val) => {
    //   tempImgs.push(val);
    //   return '#IMG#';
    // });

    // 匹配过滤词并用*替换
    // this.data.forEach((keyword) => {
    //   if (content.toUpperCase().includes(keyword.toUpperCase())) {
    //     result = false;
    //     // const asterisks: Array<'*'> = [];
    //     // for (let i = 0; i < keyword.length; i++) {
    //     //   asterisks.push('*');
    //     // }
    //     // result = result.replace(new RegExp(keyword, 'gi'), asterisks.join(''));
    //   }
    // });
    let word = null as string|null;
    for (let i = 0; i <= this.data.length; i++) {
      const word2 = this.data[i]?.toUpperCase();
      if (word2 && content.toUpperCase().includes(word2)) {
        result = false;
        word = word2;
        break;
      }
    }

    // // 恢复图片
    // if (tempImgs.length) {
    //   result = result.replace(/#IMG#/g, () => <string>tempImgs.shift());
    // }
    return {
      result,
      word,
    };
  }
}
