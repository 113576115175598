// const preFix = 'live_api_';
// const set = (key:string, val:any) :boolean => {
//   sessionStorage.setItem(preFix + key, JSON.stringify(val));
//   return true;
// };
// const get = (key:string, def_val:any) => {
//   try {
//     const rs = JSON.parse(<string>sessionStorage.getItem(preFix + key)) || def_val;
//     // console.log('取值localStorage', rs);
//     return rs;
//   } catch (error) {
//     return def_val;
//   }
// };
// !单例模式
class LocalStore {
  private static instance: LocalStore; // 类型为这个类

  // eslint-disable-next-line @typescript-eslint/no-empty-function,no-useless-constructor
  private constructor() {}

  private map ={};

  // *static 会将它挂载类上，而不是实例上
  static getInstance() {
    if (!this.instance) {
      this.instance = new LocalStore();
    }
    return this.instance;
  }

  public set(key:string, val:any):boolean {
    this.map[key]=val;
    return true;
  }

  public get(key:string, def_val:any):any {
    // eslint-disable-next-line no-prototype-builtins
    return this.map.hasOwnProperty(key) ?  this.map[key] : def_val;
  }
}

// 外部无法直接new，因为constructor是private
const liveLocalStore = LocalStore.getInstance();
export default liveLocalStore;
