import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Care from '../pages/care/index.vue';
import Login from '../pages/login/index.vue';
import Custom from '../pages/custom/index.vue';
import Test from '../pages/test/index.vue';
import Admin from '../pages/admin/index.vue';
import AddGroup from '../pages/admin/addgroup/index.vue';
import Carelist from '../pages/admin/carelist/index.vue';
import Addcare from '../pages/admin/addcare/index.vue';


Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'care',
    component: Care,
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin,
  },
  {
    path: '/addgroup',
    name: 'addgroup',
    component: AddGroup,
  },
  {
    path: '/carelist',
    name: 'carelist',
    component: Carelist,
  },
  {
    path: '/addcare',
    name: 'addcare',
    component: Addcare,
  },
  {
    path: '/custom',
    name: 'custom',
    component: Custom

  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
