<template>
  <div>
    <a-layout
      style="position: absolute; width: 100%; overflow: hidden; height: 100%"
    >
      <a-layout-header class="layout-header">
        <div class="header">
        <span class="setting">
          <img
            class="head_portrait"
            :src="care_picture"
            alt=""
          />
          <span class="username">{{$t("care.care")}}：{{care.username}}</span>
        </span>
        </div>
      </a-layout-header>

      <a-layout>
        <a-layout-content style="overflow: visible">
          <Message
            v-if="initCliented"
            :source="custom"
            :destination2="care"
            :broken="broken"
            :hideUserList="hideUserList"
            :showUserList="showUserList"
            :rtmToken="rtmToken"
            :group_id="group_id"
            :connect_id="connect_id"
            :source_role="'custom'"
            :destinationChange="destinationChange"
            :time_limit="time_limit"
            ref="messageList"
          />
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>

</template>

<script>
import Message from "../../components/chat/message.vue";
import "./index.less";
import { message } from 'ant-design-vue'
import jwt_decode from "jwt-decode";
import {getToken} from "@/libs/apis/token";
import {getUserInfo} from "@/libs/apis/author";
import {setAuthor} from "@/libs/utils/author";
import AgoraRTM from "agora-rtm-sdk";
import clientMixin from "@/mixins/clientMixin";
import {getCare} from "@/libs/apis/care";
import {getUser, saveUsers} from "@/libs/apis/user";
import {addConnect} from "@/libs/apis/connect";
import {getGroup} from "@/libs/apis/group";
import {setCareLang} from "@/libs/utils/langStore";

export default {
  data() {
    return {
      userName:'userName',
      head_portraitImg: require("../../assets/headPortrait.png"),
      collapsed: false,// 默认不收起
      broken: false,
      group_id:null,
      time_limit:180,
      connect_id:null,
      care:{
        username:'',
        picture:null,
        uid:null,
        is_care:true,
      },//客服用户信息
      rtmToken:{
        token:null,
        appid:null,
        uid:null,
      },
      custom:{
        username:'',
        picture:null,
        uid:null,
        is_care:false,
      },// 选择的对话人
      initCliented:false,
      care_picture:require("../../assets/headPortrait.png"),
    };
  },
  computed: {
  },
  watch:{
    care(newCare, oldCare){
      this.care_picture=newCare.picture;
    },
  },
  beforeMount() {
    this.init();
  },
  methods: {
    destinationChange(destination){
      this.care=destination;
      console.log(`当前客服 ${destination.username}`)
    },
    async getCare(){
     const connect=await addConnect(this.group_id,this.care.uid,this.custom.uid);
     // const care=getUser(connect.care_uid);
      // const user=await getCare();
      // if(!user) throw new Error('客服未配置');
      this.care={
        uid:connect.care.uid,
        username:connect.care.name,
        picture:connect.care.avatar,
      }
      this.connect_id=connect.id;
    },
    async init(){
      const uid=this.getQueryString('uid')
      const name=this.getQueryString('name')
      const picture=this.getQueryString('picture')
      const group_id=this.getQueryString('group_id')
      const locale=this.getQueryString('locale')??this.$i18n.locale;
      this.$i18n.locale=locale;
      setCareLang(locale);
      if(!group_id){
        this.message2postmsg('init_err',this.$t("care.needParam")+'group_id')
        return;
      }
      try {
       const group=await getGroup(group_id);
       this.time_limit=group.time_limit;
      }catch (e) {
        this.message2postmsg('init_err',this.$t("care.unGroup"))
        return;
      }
      this.group_id=group_id;
      this.custom.uid=uid?uid:this.getTouristUid();
      this.custom.username=name?name:(this.$t("care.custom")+this.custom.uid);
      this.custom.picture=picture;
      await this.getCare();
      saveUsers(this.custom.uid, this.custom.username,this.custom.picture);
      await this.getToken(this.custom);
      await this.initClient();
    },
    // 游客uid
   getTouristUid() {
      const key='care:tourist:uid';
      let uid= sessionStorage.getItem(key);
      if(!uid){
        uid=Number(new Date()) + "";
        sessionStorage.setItem(key,uid);
      }
      return uid;
    },
   checkImgExists(imgurl) {
      let ImgObj = new Image(); //判断图片是否存在
      ImgObj.src = imgurl;
      //没有图片，则返回-1
      if (ImgObj.fileSize > 0 || (ImgObj.width > 0 && ImgObj.height > 0)) {
        return true;
      } else {
        return false;
      }
    },
    async getToken (user){
      const token=await getToken(user.uid);
      this.rtmToken={
        token:token.rtmToken,
        appid:token.appId,
        uid:user.uid,
      };
    },
    async initClient(){
      const client = AgoraRTM.createInstance(this.rtmToken.appid,{
        enableLogUpload:true,
        logFilter:AgoraRTM.LOG_FILTER_INFO
      });
      await client.login(this.rtmToken);
      this.setClient(client);
      this.initCliented=true;
      client.on('ConnectionStateChanged', (newState, reason) => {
        if(newState=='DISCONNECTED'||newState=='ABORTED'){
          this.message2postmsg('offline',this.$t("care.disconnected"))
        }
      })
    },
    toLogout() {},
    onCollapse(collapsed, type) {
      if (type != "responsive") {
        this.$data.collapsed = true;
      } else {
        this.$data.collapsed = false;
      }
    },
    onBreakpoint(broken) {
      this.$data.broken = broken;
    },
    hideUserList() {
      this.$data.collapsed = true;
    },
    showUserList() {
      this.$data.collapsed = false;
    },
    select(user) {
      this.selectUser=user;
      if (this.broken) {
        this.$data.collapsed = true;
      }
    },
    onMessageFromOther(msg,send_uid){
      this.$refs.messageBox.onMessageFromOther(msg,send_uid);
    },
  },
  components: {
    Message,
  },
  mixins:[clientMixin],
};
</script>
