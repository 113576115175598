import LocalStore from "@/libs/utils/LocalStore";
import CryptoJS from "crypto-js";
import liveLocalStore from "@/libs/utils/LocalStore";
import {message} from "ant-design-vue";
import clientMixin from "@/mixins/clientMixin";
import {getAuthor} from "@/libs/utils/author";

const linkMixin = {
    data(){
        return {
          copying:false,
          copy_txt:'copy',
        };
    },
    methods:{
        createCustomLink(group_id){
            const host =window.location.protocol+'//'+ window.location.host;
            let url=`${host}/index.html#/custom?group_id=${group_id}`;
            return url;
        },
        createLink(group_id,care_uid)
        {
            const host = window.location.protocol+'//'+window.location.host;
            const Authorization=getAuthor();
            const args={
                Authorization,
                group_id:group_id,
                care_uid:care_uid,
                timestamp:Math.round(new Date() / 1000)
            };
            const sign= this.makeSignature(args,'dfoptkf9rj4jh58dfy67ft98t60fguj')
            args.sign=sign;
            let url=`${host}/index.html?`;
            let index=0;
            for(let key  in args){
                url+=((index==0? '':'&')+key+'='+args[key]);
                index++;
            }
            return url;
        },
        copy(text){
            this.copy_txt=text;
            this.copying=true;
            this.$nextTick(()=>{
                let input=document.getElementById("copy_dom");
                input.select();
                let res=document.execCommand("copy");
                this.copying=false;
                if(res==true){
                    message.success(this.$t('care.opsSuccess'));
                }
            })
        },
    },
    mixins:[clientMixin],
}
export default linkMixin;
