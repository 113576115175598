
<template>
	<div class="group">
    <a-spin :spinning="spinning" tip="Loading...">
      <el-card class="box-card">
        <el-form v-if="!is_new" ref="form" :model="form" label-width="80px">
          <el-form-item :label="$t('care.choice')">
            <el-select

                size="mini"
                v-model="form.uid"
                filterable
                remote
                reserve-keyword
                :placeholder="$t('care.inputKeyword')"
                @change="change"
                :remote-method="getUsers"
                :loading="loading">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <div class="btns">
            <el-button size="mini" plain type="primary" @click="onSubmit">{{$t("care.choiced")}}</el-button>
            <el-button size="mini" plain @click="onCancel">{{$t("care.cancel")}}</el-button>
          </div>
          <div class="btns" style="margin-top: 20px">
            <el-button size="mini" plain type="primary" style="margin-left: 10px" @click="is_new=true;">{{$t("care.unFindUser")}}</el-button>
          </div>
        </el-form>

        <el-form v-else :model="form_new" label-width="80px" ref="formnew"
                 :rules="rules">
          <el-form-item :label="$t('care.name')" prop="name">
            <el-input v-model="form_new.name" :placeholder="$t('care.nameplaceholder')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('care.avatar')" prop="avatar">
            <el-upload
                :class="['avatar-uploader',form_new.avatar?'avatar-uploader-ok':'']"
                action="string"
                :show-file-list="false"
                :before-upload="onBeforeUploadImage"
                :http-request="upload">
              <img v-if="form_new.avatar" :src="form_new.avatar" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>

          <div class="btns">
            <el-button size="mini" plain type="primary" @click="onSubmitNew('formnew')">{{$t("care.add")}}</el-button>
            <el-button size="mini" plain @click="is_new=false">{{$t("care.cancel")}}</el-button>
          </div>
        </el-form>
      </el-card>
    </a-spin>
	</div>
</template>

<script>
import "./index.less";
import { message } from 'ant-design-vue';
import {addGroupCare} from "@/libs/apis/groupcare";
import careMixin from "@/mixins/careMixin";
import {getUsers, saveUsers} from "@/libs/apis/user";
import axios from "axios";
import uploadMixin from "@/mixins/uploadMixin";

export default{
	data(){
		return {
      is_new:false,
      options:[],
      loading:false,
      auth_url:null,
      spinning:false,
      form: {
        uid: null,
      },
      form_new:{
        name: null,
        avatar: null,
      },
      uid:null,
      rules:{
        name: [
          { required: true, message: this.$t('care.nameplaceholder'), trigger: 'blur' },
        ],
        avatar: [
          { required: true, message: this.$t('care.avatarplaceholder'), trigger: 'blur' }
        ],
      },
		};
	},
	components: {},
  computed: {
  },
  mounted() {
    this.auth_url=localStorage.getItem('auth_url');
    this.getUsers('');
  },
  methods: {
    onBeforeUploadImage(file) {
      return true;
    },
    upload(param){
      console.log(param)
      const formData = new FormData()
      formData.append('file[]', param.file)
      // 没有什么参数
      let args={
        'acl':'public-read',
      };
      for(let key  in args){
        formData.append(key,args[key]);
      }
      const sign=this.makeSignature(args,'as2d4f5g6h7j8gfzcvbrgj8')
      formData.append('sign', sign)
      // axios.interceptors.request.use((config) => {
      //   // eslint-disable-next-line no-param-reassign
      //   if (!config.headers) config.headers = {};
      //   // eslint-disable-next-line no-param-reassign
      //   config.headers.Authorization = `Bearer ${getAuthor()}`;
      //   return config;
      // }, (error) => Promise.reject(error));
      axios.post('https://static.toptopone.com/v2/common/upload',formData).then(rs=>{
         this.form_new.avatar=rs.data.data[0].signed_url;
      }).catch(err=>{
         message.error(this.$t('care.uploadFail'))
      });
    },
    change(uid){
      this.uid=uid;
    },
    async getUsers(query){
      try {
        const users= await getUsers(this.owner_id,{filter:{'name':query, }});
        const options=[];
        users.data.map((user)=>{
          options.push({
            value:user.uid,
            label:user.name
          })
        })
        this.options=options;
      }catch (e) {
        message.error(e.data?e.data.message:this.$t('care.getUsersErr'));
      }
    },
    onCancel(){
      this.$router.back();
    },
    async onSubmit() {
      if(!this.uid) {
        message.warning(this.$t('care.choiceUser'))
        return
      }
      try {
        this.spinning=true;
        // const user=await getUserDetails(this.uid,this.auth_url,{'x-user-id':'387965240832163989'})
        // console.log(user)
        await addGroupCare(this.group_id
            ,this.uid);
        this.spinning=false;
        message.success(this.$t('care.opsSuccess'))
        this.$router.back();
      }catch (e) {
        this.spinning=false;
        message.error(e);
      }

    },
    async onSubmitNew(formName){
       if(!this.form_new.name||!this.form_new.avatar){
         message.error(this.$t('care.form_new'))
         return;
       }
      this.spinning=true;
      const user=await saveUsers(null,this.form_new.name,this.form_new.avatar,this.owner_id);
      await addGroupCare(this.group_id ,user.uid);
      this.spinning=false;
      this.$router.back();
    }
	},
  mixins:[careMixin,uploadMixin]
};
</script>
